import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <p>
          You can use the app on multiple devices as long as you login using the
          same account. However syncing progress across devices is only
          available for Premium users.
        </p>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
